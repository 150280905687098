import { connect } from 'react-redux';
import { store } from '../store';
import { Link } from "react-router-dom";
import "../scss/options.scss";
import Container from 'react-bootstrap/Container';
import { CheckMobile } from '../actions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Options(props) {

    var mobile = props.mobile;

    if (window.innerWidth < 768) {
      store.dispatch(CheckMobile(true))
    }
  
    return (
        <div className="options">

            <Container>
                <Row>
                    <Col xs={12} md={3}>
                        <Link to="/servicos/rodoviario">
                            <div    style={{ backgroundImage: mobile ? "url('../rodo-mobile.png')": "url('../rodo.png')", }} className={mobile ? "options__col text-focus-in" : "options__col scale-up-center"}>
                                <h1 className="options__col__link">Transporte Rodoviário</h1>
                            </div>
                        </Link>
                    </Col>

                    <Col xs={12} md={3}>
                        <Link to="/servicos/especiais">
                            <div    style={{ backgroundImage: mobile ? "url('../especiais-mobile.png')": "url('../especiais.png')", }} className={mobile ? "options__col text-focus-in" : "options__col scale-up-center"}>
                                <h1 className="options__col__link claro">Transportes Especiais</h1>
                            </div>
                        </Link>
                    </Col>

                    <Col xs={12} md={3}>
                        <Link to="/servicos/aereo">
                            <div style={{ backgroundImage: mobile ? "url('../aereo-mobile.png')": "url('../aereo.png')", }} className={mobile ? "options__col text-focus-in" : "options__col scale-up-center"}>
                                <h1 className="options__col__link">Transporte Aéreo</h1>
                            </div>
                        </Link>
                    </Col>

                    <Col xs={12} md={3}>
                        <Link to="/servicos/transferencia">
                            <div  style={{ backgroundImage: mobile ? "url('../transfer-mobile.png')": "url('../transfer.png')", }} className={mobile ? "options__col text-focus-in" : "options__col scale-up-center"}>
                                <h1 className="options__col__link claro">Modalidade Transferência</h1>
                            </div>
                        </Link>
                    </Col>

                    
                </Row>
            </Container>

        </div>

    )

}


function mapStateToProps(state) {
    return {
      mobile: state.mobile
    }
  }
  
  export default connect(
    mapStateToProps
  )(Options);