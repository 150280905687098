import "../scss/footer.scss";

function Footer() {

    return (

            <div className="footer">
                <h1 className="footer__text">MRM Transportes © 2024</h1>
            </div>

    )
  
}


export default Footer