import "../scss/app.scss";
import Menu from './Menu';
import Options from './Options';



function App() {

    return (
        <div id='home'>

            <video autoPlay loop muted className="video">
                <source src="/fundo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>


            <Menu></Menu>

            <Options></Options>

        </div>
    )
  
}


export default App