import { combineReducers } from 'redux';


const checkMobileReducer = (mobile = false, action) => {
    if (action.type === 'CHECK_MOBILE') {
  
      return action.payload;
      
    }
    
    return mobile;
};

const openCloseReducer = (open = false, action) => {
  if (action.type === 'OPEN_CLOSE_MENU') {

    return action.payload;
    
  }
  
  return open;
};


const GetServiçosReducer = (serviços = null, action) => {
  if (action.type === 'GET_SERVIÇOS') {

    return action.payload;
    
  }
  
  return serviços;
};


export default combineReducers({
    mobile: checkMobileReducer,
    open: openCloseReducer,
    serviços: GetServiçosReducer
});