import { connect } from 'react-redux';
import { store } from '../store';
import "../scss/servicos.scss";
import Menu from './Menu';
import Footer from './Footer';
import { useParams } from "react-router-dom";
import { GetServiços } from '../actions';
import Carousel from 'react-bootstrap/Carousel';

function Serviços(props) {

    var serviços = props.serviços;

    var { id } = useParams();

    
    if (serviços === null || id !== serviços[0].url) {
        
        store.dispatch(GetServiços(id))

        return (
            <div></div>
        )

    } else {
        return (
            <div id='servicos' style={{ backgroundImage: "url('../background2.png')" }}>
    
                <Menu></Menu>

                <div className="servicos">
                    
                    <h1 className="servicos__title">{serviços[0].categoria}</h1>

                    <Carousel controls={serviços.length > 1 ? true:false} indicators={serviços.length > 1 ? true:false}>
                        { serviços.map( (serviço, index) => 
                        <Carousel.Item key={index}>
                            <div className="servicos__servico">
                                <h2 className="servicos__servico__title">{serviço.nome}</h2>

                                <div className='servicos__servico__info'>
                                    
                                    <h3 className="servicos__servico__info__title">Tipo de Carga</h3>

                                    <p className="servicos__servico__info__text">{serviço.tipo}</p>
                                </div>

                                {serviço.prazo_img !== null ? (
                                <img className="servicos__servico__img" src={serviço.prazo_img} alt="Prazo" />
                                ) : (
                                    <div className='servicos__servico__info'>
                                    
                                    <h3 className="servicos__servico__info__title">Prazo</h3>

                                    <p className="servicos__servico__info__text">{serviço.prazo_texto}</p>
                                </div>

                                )}

                            </div>
                        </Carousel.Item>
                        )}
                    </Carousel>
                </div>

                <Footer></Footer>
    
    
            </div>
        )
    }

  
}

function mapStateToProps(state) {
    return {
        serviços: state.serviços
    }
}

export default connect(
mapStateToProps
)(Serviços);