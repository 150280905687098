import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import App from './components/App';
import Sobre from './components/Sobre';
import Contato from './components/Contato';
import Serviços from './components/Serviços';
import {store} from './store.js';
import 'bootstrap/dist/css/bootstrap.min.css';



ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}></Route>
          <Route path="/sobre" element={<Sobre />}></Route>
          <Route path="/contato" element={<Contato />}></Route>
          <Route path="/servicos/:id" element={<Serviços />}></Route>
        </Routes>
      </BrowserRouter>
  </Provider>,
  document.querySelector('#root')
)