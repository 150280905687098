import { connect } from 'react-redux';
import { store } from '../store';
import "../scss/menu.scss";
import { Link } from "react-router-dom";
import { CheckMobile } from '../actions';
import { OpenCloseMenu } from '../actions';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Menu(props) {

    var mobile = props.mobile;

    var open = props.open;

    if (window.innerWidth < 768) {
      store.dispatch(CheckMobile(true))
    }

    function openCloseMenu() {
        open = !open;
    
        store.dispatch(OpenCloseMenu(open))
      }
    
      function closeMenu() {
        if (window.innerWidth < 767 && open === true) {
          open = !open;
    
          store.dispatch(OpenCloseMenu(open))
        }
      }
  
    return (
        <div>
            {
                mobile === false?
                <div className="menu">
                <Container>
                    <Row>
        
                        <Col md={2}>
                            <Link to="/">
                                <img src="/images/logomrm.png" className="menu__logo slide-in-left"alt="" />
                            </Link>
                        </Col>
        
                        <Col md={1}></Col>
        
                        <Col md={3}>
                            <Link to="/" className="menu__link text-flicker-in-glow">serviços</Link>
                        </Col>
        
                        <Col md={3}>
                            <Link to="/sobre" className="menu__link text-flicker-in-glow">sobre nós</Link>
                        </Col>
        
                        <Col md={3}>
                            <Link to="/contato" className="menu__link text-flicker-in-glow">contato</Link>
                        </Col>
                    </Row>
                </Container>
                </div>
                :<div className="menu">
                    <Container>
                        <Row>
            
                            <Col xs={6}>
                                <Link to="/">
                                    <img onClick={closeMenu} src="/images/logomrm.png" className="menu__logo text-flicker-in-glow"alt="" />
                                </Link>
                            </Col>

                            <Col xs={2}></Col>
            
                            <Col xs={3}>

                                {
                                    open === true ?
                                    <div id="open">
                                        <img onClick={openCloseMenu} src="/mobile.png" className="menu__mobile rotate" alt="" />

                                        <div className='open'>
                                            <Link onClick={closeMenu} to="/" className="open__link">serviços</Link>
                                            <Link onClick={closeMenu} to="/sobre" className="open__link">sobre nós</Link>
                                            <Link onClick={closeMenu} to="/contato" className="open__link">contato</Link>
                                        </div>

                                    </div>
                                    :<div>
                                        <img onClick={openCloseMenu} src="/mobile.png" className="menu__mobile text-flicker-in-glow" alt="" />
                                    </div>
                                }

                            </Col>
            
                        </Row>
                    </Container>
                </div>
            }

        </div>

    )

}


function mapStateToProps(state) {
    return {
      mobile: state.mobile,
      open: state.open,
    }
  }
  
  export default connect(
    mapStateToProps
  )(Menu);